import { unstable_HistoryRouter as Router, useNavigate } from 'react-router-dom';
import TabBarView from '@/layout/TabBarView';
import RouteRender from '@/routers/RouteRender';
import history from '@/utils/history';
import {useEffect} from 'react';
import 'dayjs/locale/zh-cn';
const App = () => {
    
  return (
    <>
      <Router history={history}>
        <RouteRender />
        {/* <TabBarView /> */}
      </Router>
    </>
  );
};
export default App;
