/*
 * @Descripttion:
 * @version:
 * @Author: 小白
 * @Date: 2020-10-05 22:06:34
 * @LastEditors: 小白
 * @LastEditTime: 2022-02-17 00:14:09
 */
import { White } from '@/typings';
import { lazy } from 'react';
const Login = lazy(() => import("@/pages/Login"));
const Search = lazy(() => import(/* chunkName: "Search" */ '@/pages/Search'));
const List = lazy(() => import(/* chunkName: List */ '@/pages/List'));
const Home = lazy(() => import(/* chunkName: Home */ '@/pages/Home'));
const Detail = lazy(() => import(/* chunkName: Detail */ '@/pages/Detail'));
const Test = lazy(() => import(/* chunkName: Index */ '@/pages/Test'));
const CardApplyList = lazy(() => import(/* chunkName: CardApplyList */ '@/pages/CardApplyList'));
// eslint-disable-next-line no-import-assign
const SFProducts = lazy(() => import(/* chunkName: SFProducts */ '@/pages/SFProducts'));
const SHProducts = lazy(() => import(/* chunkName: SHProducts */ '@/pages/SHProducts'));
const SFDataPlans = lazy(() => import(/* chunkName: SFDataPlans */ '@/pages/SFDataPlans'));
const SFDataPlansDetail = lazy(() => import(/* chunkName: SFDataPlans */ '@/pages/SFDataPlansDetail'));
const GRDXDataPlansDetail = lazy(() => import(/* chunkName: SFDataPlans */ '@/pages/GRDXDataPlansDetail'));
const GXDProducts = lazy(() => import(/* chunkName: SFProducts */ '@/pages/GXDProducts'));
const GXDDataPlansDetail = lazy(() => import(/* chunkName: SFDataPlans */ '@/pages/GXDDataPlansDetail'));
const Other = lazy(() => import(/* chunkName: Other */ '@/pages/Other'));
const HTDProducts = lazy(() => import(/* chunkName: SFProducts */ '@/pages/HTDProducts'));
const YHProducts = lazy(() => import(/* chunkName: SFProducts */ '@/pages/YHProducts'));
const HNProducts = lazy(() => import('@/pages/HNProducts'));
const OrderQuery = lazy(() => import(/* chunkName: OrderQuery */ '@/pages/OrderQuery'));
const CarouselPage = lazy(() => import(/* chunkName: CarouselPage */ '@/pages/CarouselPage'));
const ZYHYProducts = lazy(() => import('@/pages/ZYHYProducts'));
const ZYYDProducts = lazy(() => import('@/pages/ZYYDProducts'));
const CSMProducts = lazy(() => import('@/pages/CSMProducts'));
const XYTProducts = lazy(() => import('@/pages/XYTProducts'));
const FCProducts = lazy(() => import('@/pages/FCProducts'));
const JRYXProducts = lazy(() => import('@/pages/JRYXProducts'));
const Information = lazy(() => import('@/pages/Information'));
const Pay = lazy(() => import('@/pages/Pay'));
const Register = lazy(() => import('@/pages/Register'));
const PLogin = lazy(() => import('@/pages/PLogin'));
const NoFound = lazy(
  () => import(/* chunkName: NoFound */ '../components/NoFound'),
);
export const TabBarList: White.RouteTabBar[] = [
  {
    path: '/home',
    component: Home,
    icon: 'white-home1',
    sceneMode: 'scroll',
    title: '首页',
  },
  {
    path: '/detail',
    component: Detail,
    icon: 'white-tradingdata',
    sceneMode: 'scroll',
    title: '详情',
  },
  {
    path: '/list',
    component: List,
    icon: 'white-order',
    sceneMode: 'scroll',
    title: '统计',
  },
  {
    path: '/search',
    component: Search,
    icon: 'white-account',
    sceneMode: 'scroll',
    title: '我的',
  },
];

const routes: White.RouteConfig[] = [
  {
    path: '/',
    component: Home,
    title: '首页'
  },
  {
    path: '/home',
    component: Home,
    title: '首页'
  },
  {
    path: '/information',
    component: Information,
    title: '行业资讯'
  },
  {
    path: '/pay',
    component: Pay,
    title: '支付'
  },
  {
    path: '/register',
    component: Register,
    title: '注册'
  },
  {
    path: '/pLogin',
    component: PLogin,
    title: '登录'
  },
  {
    path: '/products/sf',
    component: SFProducts,
  },
  {
    path: '/products/sfdp',
    component: SFDataPlans,
  },
  {
    path: '/products/gxd',
    component: GXDProducts,
  },
  {
    path: '/products/yh',
    component: YHProducts,
  },
  {
    path: '/products/htd',
    component: HTDProducts,
  },
  {
    path: '/products/hn',
    component: HNProducts,
  },
  {
    path: '/products/sh',
    component: SHProducts,
  },
  {
    path: '/products/zyhy',
    component: ZYHYProducts,
  },
  {
    path: '/products/zyyd',
    component: ZYYDProducts,
  },
  {
    path: '/products/csm',
    component: CSMProducts,
  },
  {
    path: '/products/xyt',
    component: XYTProducts,
  },
  {
    path: '/products/fc',
    component: FCProducts,
  },
  {
    path: '/products/jryx',
    component: JRYXProducts,
  },
  {
    path: '/product/telecom',
    component: Other,
  },
  {
    path: '/product/carouselPage',
    component: CarouselPage ,
  },
  {
    path: '/product/orderQuery',
    component: OrderQuery,
  },
  {
    path: '/dpproduct/telecom',
    component: SFDataPlansDetail,
  },
  {
    path: '/gxddpproduct/telecom',
    component: GXDDataPlansDetail,
  },
  {
    path: '/grdpproduct/telecom',
    component: GRDXDataPlansDetail,
  },
  {
    path: '/login',
    sceneMode: 'bottom',
    component: Login,
  },
  {
    path: '/test',
    component: Test,
  },
  {
    path: '/applyList',
    component: CardApplyList,
  },

  {
    path: '*',
    component: NoFound,
  },
];

export default [...routes];
