/*
 * @Descripttion: 工具类
 * @version:
 * @Author: 小白
 * @Date: 2020-10-04 13:11:40
 * @LastEditors: 小白
 * @LastEditTime: 2022-02-16 23:53:34
 */
import axios from 'axios';

export const setAuth = (access: string, refresh:string) => {
  window.localStorage.setItem('access', access);
  window.localStorage.setItem('refresh', refresh);
};

export const getAuth = () => {
  const access = window.localStorage.getItem('access');
  const refresh = window.localStorage.getItem('refresh');
  return [access || '', refresh || '']
};

export const getAccess = () => {
  const access = window.localStorage.getItem('access');
  return access;
};

export const getCode = () => {
  return window.location.search
    ? searchObj(window.location.search).code
    : window.location.pathname.split('/')[2];
};
/**
 * 获取url参数
 * @param search url参数
 */
export const searchObj = (search: string) => {
  const body = JSON.parse(
    '{"'.concat(
      decodeURIComponent(search.substring(1))
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"'),
      '"}',
    ),
  );
  return body;
};

export const treeToList = (list: any[], parents: string | string[]) => {
  let adtaList: any[] = [];
  list.forEach((v) => {
    if (typeof parents === 'string') {
      if (v[parents]) {
        adtaList = [...adtaList, ...treeToList(v[parents], parents)];
      } else {
        adtaList.push(v);
      }
    } else {
      let isHave = false;
      parents.forEach((parent) => {
        if (v[parent]) {
          adtaList = [...adtaList, ...treeToList(v[parent], parents)];
          isHave = true;
        }
      });
      if (!isHave) {
        adtaList.push(v);
      }
    }
  });
  return adtaList;
};

export const setWindowHeight = () => {
  const windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;
  if (typeof windowWidth !== 'number') {
    if (document.compatMode === 'CSS1Compat') {
      windowHeight = document.documentElement.clientHeight;
    } else {
      // @ts-ignore
      windowHeight = window.body.clientHeight;
    }
  }
  document
    .getElementsByTagName('body')[0]
    .style.setProperty('--height-primary', `${windowHeight}px`);
};

export const  downLoadExcel=(trs, data, fileName)=> {
    // 定义表头
    let str = trs;
    // 增加\t为了不让表格显示科学计数法或者其他格式
    for (let i = 0; i < data.length; i++) {
        for (const item in data[i]) {
            //str += `${data[i][item] + '\t'},`;
            str += `${data[i][item]},`;
        }
        // str = str.substr(0, str.length - 2)
        // console.log(str)
        str += '\n';
    }
    // encodeURIComponent解决中文乱码
    const uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str);
    // 通过创建a标签实现
    const linkData = document.createElement("a");
    linkData.href = uri;
    // 对下载的文件命名
    linkData.download = `${fileName || '表格数据'}.xls`;
    document.body.appendChild(linkData);
    linkData.click();
    document.body.removeChild(linkData);
}

export const downLoad = (url) => {
  console.log('Downloading: ' + url)
  const linkData = document.createElement("a");
  linkData.href = url;
  document.body.appendChild(linkData);
  linkData.click();
  document.body.removeChild(linkData);
}

export const formatTimeStamp = (timeStamp) => {
  timeStamp = parseFloat(timeStamp) * 1000
  let date = new Date(+timeStamp)
  let y = date.getFullYear()
  let m:any = date.getMonth() + 1
  m = m < 10 ? ('0' + m) : m
  let d:any = date.getDate()
  d = d < 10 ? ('0' + d) : d
  let h:any = date.getHours()
  h = h < 10 ? ('0' + h) : h
  let minute:any = date.getMinutes()
  let second:any = date.getSeconds()
  minute = minute < 10 ? ('0' + minute) : minute
  second = second < 10 ? ('0' + second) : second
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
}


// export default auth;
